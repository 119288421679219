import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import musicPlayer from "./component/musicPlayer";
import TodoList from "./component/todoList";
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  data: function data() {
    return {
      toolCards: [{
        label: "用户管理",
        icon: "el-icon el-icon-monitor",
        name: "user",
        color: "#ff9c6e"
      }, {
        label: "角色管理",
        icon: "el-icon el-icon-setting",
        name: "authority",
        color: "#69c0ff"
      }, {
        label: "菜单管理",
        icon: "el-icon el-icon-menu",
        name: "menu",
        color: "#b37feb"
      }, {
        label: "代码生成器",
        icon: "el-icon el-icon-cpu",
        name: "autoCode",
        color: "#ffd666"
      }, {
        label: "表单生成器",
        icon: "el-icon el-icon-document-checked",
        name: "formCreate",
        color: "#ff85c0"
      }, {
        label: "关于我们",
        icon: "el-icon el-icon-user",
        name: "about",
        color: "#5cdbd3"
      }]
    };
  },
  computed: _objectSpread({}, mapGetters('user', ['userInfo'])),
  components: {
    musicPlayer: musicPlayer,
    //音乐播放器
    TodoList: TodoList //TodoList
    // RaddarChart, //雷达图
    // stackMap, //堆叠图
    // Sunburst, //旭日图

  },
  methods: {
    toTarget: function toTarget(name) {
      this.$router.push({
        name: name
      });
    }
  }
};