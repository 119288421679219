var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "big" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "car-left" }, [
        _c("div", [
          _c("span", { staticClass: "card-img" }, [
            _c("img", { attrs: { src: _vm.userInfo.headerImg, alt: "" } })
          ]),
          _vm._m(0)
        ])
      ]),
      _c(
        "div",
        { staticClass: "car-right" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "car-item" }, [
                  _c("span", { staticClass: "flow" }, [
                    _c("i", { staticClass: "el-icon-s-grid" })
                  ]),
                  _c("span", [_vm._v("今日流量 ")]),
                  _c("b", [_vm._v("13260")])
                ])
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "car-item" }, [
                  _c("span", { staticClass: "user-number" }, [
                    _c("i", { staticClass: "el-icon-s-custom " })
                  ]),
                  _c("span", [_vm._v("总用户 ")]),
                  _c("b", [_vm._v("48286")])
                ])
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "car-item " }, [
                  _c("span", { staticClass: "feedback" }, [
                    _c("i", { staticClass: "el-icon-star-on" })
                  ]),
                  _c("span", [_vm._v("好评率 ")]),
                  _c("b", [_vm._v("98%")])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "shadow" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          _vm._l(_vm.toolCards, function(card, key) {
            return _c(
              "el-col",
              {
                key: key,
                attrs: { span: 4 },
                nativeOn: {
                  click: function($event) {
                    return _vm.toTarget(card.name)
                  }
                }
              },
              [
                _c(
                  "el-card",
                  { staticClass: "grid-content", attrs: { shadow: "hover" } },
                  [
                    _c("i", { class: card.icon, style: { color: card.color } }),
                    _c("p", [_vm._v(_vm._s(card.label))])
                  ]
                )
              ],
              1
            )
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 32 } },
          [
            _c("el-col", { attrs: { xs: 24, sm: 24, lg: 12 } }, [
              _c("div", { staticClass: "chart-player" }, [_c("musicPlayer")], 1)
            ]),
            _c("el-col", { attrs: { xs: 24, sm: 24, lg: 12 } }, [
              _c("div", { staticClass: "chart-player" }, [_c("todo-list")], 1)
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("h4", [_vm._v("早安，管理员， 请开始您一天的工作吧！")]),
      _c("p", { staticClass: "tips-text" }, [
        _c("i", { staticClass: "el-icon-sunny" }),
        _c("span", [_vm._v("今日晴，0℃ - 10℃，天气寒冷，注意添加衣物。")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }